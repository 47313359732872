import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-card-container" }
const _hoisted_2 = { class: "analytics-card-heading-container" }
const _hoisted_3 = { class: "analytics-card-heading-text" }
const _hoisted_4 = { class: "analytics-card-right-component-container" }


export default /*@__PURE__*/_defineComponent({
  __name: 'AnalyticsCard',
  props: {
  heading: {
    type: String,
    required: true
  }
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(__props.heading), 1),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "rightComponent")
      ])
    ]),
    _renderSlot(_ctx.$slots, "content")
  ]))
}
}

})