<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore, useOnboardingStore } from '@/store'

const { isAuthenticated } = useAuthStore()
const onboardingStore = useOnboardingStore()
const { setMode } = onboardingStore

const headerText = 'Complete QR code platform'
const headerStartText = 'Generate, manage, and track QR codes with custom landing pages.'
const headerButtonText = 'Get Started'

const router = useRouter()

function onNavigateLogin () {
  setMode('login')
  router.push('/login')
}

function onNavigateRegister () {
  setMode('register')
  router.push('/login')
}

function onNavigateDashboard () {
  setMode('login') // Necessary?
  router.push('/dashboard')
}

function windowScroll () {
  const navbar = document.getElementById('sticky')
  if (navbar) {
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      navbar.classList.add('nav-sticky')
    } else {
      navbar.classList.remove('nav-sticky')
    }
  }
}
onMounted(async () => {
  window.addEventListener('scroll', (ev) => {
    ev.preventDefault()
    windowScroll()
  })
})
</script>

<template>
    <div class="landing-container container-fluid">
      <div class="top-bar">
        <img class="top-bar-logo" src="@/assets/logo-grad.png"/>
        <img class="top-bar-logo-text" src="@/assets/logo-text.png"/>
        <div class="top-bar-links">
          <!--<a href="#" class="top-bar-link">Features</a>
          <a href="#" class="top-bar-link">Solutions</a>
          <a href="#" class="top-bar-link">Pricing</a>
          <a href="#" class="top-bar-link">Resources</a>-->
        </div>
      <div v-if="isAuthenticated" class="top-bar-dash">
        <div class="top-bar-dash-button top-bar-dash-button-start top-bar-dash-button-start-scroll" @click="onNavigateDashboard">
          <p class="top-bar-dash-button-text">Dashboard</p>
        </div>
      </div>
      <div v-else class="top-bar-dash">
        <div class="top-bar-dash-button top-bar-dash-button-login" @click="onNavigateLogin">
          <p class="top-bar-dash-button-text">Login</p>
          </div>
        <div class="top-bar-dash-button top-bar-dash-button-start" @click="onNavigateRegister">
          <p class="top-bar-dash-button-text">Get Started</p>
        </div>
      </div>
      </div>
      <div class="header-banner-container">
        <div class="header-text-container">
          <p class="header-text">{{ headerText }}</p>
          <div class="header-start-container">
            <p class="header-start-text">{{ headerStartText }}</p>
            <div class="header-start-button" @click="onNavigateRegister">
              <p class="header-start-button-text">{{ headerButtonText }}</p>
            </div>
          </div>
        </div>
        <div class="header-carousel-container">
          <img class="header-carousel-image" src="@/assets/images/figma-qr-codes-scrolling.png"/>
        </div>
      </div>
    </div>
  </template>

<style scoped lang="scss">
  div.landing-container{
    width: 100vw;
    height: 100vh;
    padding: 0 3.5rem;
  }
  div.top-bar {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #FFF;
    display: flex;
    height: 2.1rem;
    align-items: center;
  }
  img.top-bar-logo {
    width: 1.15rem;
    height: 1.15rem;
  }
  img.top-bar-logo-text {
    width: 1.7rem;
    height: 0.5rem;
    margin-left: 0.35rem;
  }
  div.top-bar-links {
    margin-left: 1.95rem;
    display: flex;
    align-items: center;
  }
  a.top-bar-link {
    margin: 0 0.65rem;
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-16);
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.48px;
    text-decoration: none;
  }
  div.top-bar-dash {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  div.top-bar-dash-button {
    display: inline-flex;
    padding: 0.35rem 0.64rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 0.25rem;
  }

  p.top-bar-dash-button-text {
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.42px;
    margin: 0;
  }

  div.top-bar-dash-button-login {
    background-color: var(--color-white);
    box-shadow: 0 0 0 2px rgba(33, 37, 41, 0.10) inset;
    color: var(--color-nearly-black);
    cursor: pointer;
  }

  div.top-bar-dash-button-start {
    color: var(--color-nearly-black);
    background-color: var(--color-light-grey);
    margin-left: 0.32rem;
    cursor: pointer;
  }

  div.top-bar-dash-button-start .top-bar-dash-button-start-scroll {
    color: var(--color-white);
    background-color: transparent;
    background: linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%);
  }

  div.header-banner-container {
    display: flex;
    width: 100%;
    height: 75vh;
    margin-top: 3.2rem;
  }

  div.header-text-container, div.header-carousel-container {
    display: flex;
  }

  div.header-text-container {
    flex: 0.7;
    padding-right: 1.92rem;
  }

  div.header-text-container {
    flex-direction: column;
    justify-content: space-between;
  }
  p.header-text {
    color: var(--color-nearly-black);
    font-family: whyte;
    font-size: var(--font-size-60);
    font-weight: 500;
    letter-spacing: -2.4px;
    margin: 0
  }

  div.header-start-container {
    display: flex;
    flex-direction: column;
  }

  p.header-start-text {
    color: var(--color-nearly-black9);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 500;
    letter-spacing: -0.6px;
  }

  div.header-start-button {
    width: 4.7rem;
    height: 1.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.38rem;
    background: var(--color-nearly-black);
    cursor: pointer;
  }

  p.header-start-button-text {
    color: var(--color-white);
    font-family: Inter;
    font-size: var(--font-size-16);
    font-weight: 500;
    margin: 0;
  }

  div.header-carousel-container {
    background: var(--color-nearly-black);
    flex: 1;
    border-radius: 24px;
    padding: 0.45rem;
  }

  img.header-carousel-image {
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    opacity: 1.0;
  }
</style>
