import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SvgLink from '@/components/button/SvgLink.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavExportCSVButton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SvgLink, {
    icon: "csv",
    style: {
      minWidth: 'max-content',
      whiteSpace: 'nowrap',
      padding: '0.42rem 0.64rem',
      borderRadius: '0.26rem',
      background: 'var(--color-white)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    "icon-style": {
      width: '0.36rem',
      height: '0.36rem'
    },
    "text-style": {
      color: 'var(--color---color-nearly-black)',
      fontFamily: 'Inter',
      fontSize: 'var(--font-size-14)',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '14px',
      marginLeft: '0.32rem'
    }
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" Export CSV ")
    ])),
    _: 1
  }))
}
}

})