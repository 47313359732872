import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "location-scans-card-content" }
const _hoisted_2 = { class: "location-scans-card-label-text" }
const _hoisted_3 = { class: "location-scans-card-value-text" }

import { LabelValue } from '@/api/types'

import AnalyticsCard from '@/components/analytics/AnalyticsCard.vue'
import KPIComponent from './KPIComponent.vue'

const headingText = 'Scans by location'


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationScansCard',
  props: {
  locationScans: {
    type: Array as () => LabelValue[],
    required: true
  }
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AnalyticsCard, { heading: headingText }, {
    rightComponent: _withCtx(() => _cache[0] || (_cache[0] = [])),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.locationScans, ({label, value}) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "location-scans-card-row",
            key: label
          }, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(label), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(value), 1)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})