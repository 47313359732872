<script setup lang="ts">
import { computed } from 'vue'

import AnalyticsCard from './AnalyticsCard.vue'
import KPIComponent from './KPIComponent.vue'

import { IntTimeSeriesValue } from '@/types'

const { value, percentageChange, scansSeries, clicksSeries } = defineProps({
  value: {
    type: String,
    required: true
  },
  percentageChange: {
    type: Number,
    required: true
  },
  scansSeries: {
    type: Array as () => IntTimeSeriesValue[],
    required: true
  },
  clicksSeries: {
    type: Array as () => IntTimeSeriesValue[],
    required: true
  }
})

const chartSeries = computed(() => [
  {
    name: 'Scans',
    data: scansSeries.map((item: any) => item.value)
  },
  {
    name: 'Clicks',
    data: clicksSeries.map((item: any) => item.value)
  }
])

const chartOptions = computed(() => {
  return {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false
      }
    },
    colors: ['#7A3DFF', '#FF7809'],
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: false,
      fontSize: 12,
      // color: var(--Nearly-Black, #212529);

      fontFamily: 'Inter',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      clusterGroupedSeries: true,
      clusterGroupedSeriesOrientation: 'vertical',
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      markers: {
        size: 7,
        style: 'line',
        offsetY: 1.5,
        offsetX: -5
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0
      },
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      }
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      type: 'datetime',
      categories: scansSeries.map((item: any) => item.timestamp)
    },
    yaxis: {
      show: false
    }
  }
})

</script>

<template>
    <AnalyticsCard heading="Scans">
        <template #rightComponent>
            <KPIComponent :value="value" :change="percentageChange" />
        </template>
        <template #content>
            <div>
                <apexchart type="area" height="350" :options="chartOptions" :series="chartSeries"></apexchart>
            </div>
        </template>
    </AnalyticsCard>
</template>

<style scoped lang="scss">

</style>
