import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "kpi-container" }
const _hoisted_2 = { class: "kpi-change-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "kpi-value-text" }

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'KPIComponent',
  props: {
  value: {
    type: String,
    required: true
  },
  change: {
    type: Number,
    required: true
  },
  changeSuffix: {
    type: String,
    default: '%'
  }
},
  setup(__props) {



const imgSrc = computed(() => {
  const icon = __props.change >= 0 ? 'inc-green' : 'dec-red'
  return require(`@/assets/icons/svg/kpi-${icon}.svg`)
})

const cssVarsChangeText = computed(() => {
  return {
    color: __props.change >= 0 ? 'var(--color-kpi-green)' : 'var(--color-red)'
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: imgSrc.value,
        alt: "icon",
        class: "kpi-change-image"
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        style: _normalizeStyle(cssVarsChangeText.value),
        class: "kpi-change-text"
      }, _toDisplayString(`${__props.change.toFixed(1)}${__props.changeSuffix}`), 5)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(__props.value), 1)
  ]))
}
}

})