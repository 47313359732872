import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

import AnalyticsCard from './AnalyticsCard.vue'
import KPIComponent from './KPIComponent.vue'

import { IntTimeSeriesValue } from '@/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ScansCard',
  props: {
  value: {
    type: String,
    required: true
  },
  percentageChange: {
    type: Number,
    required: true
  },
  scansSeries: {
    type: Array as () => IntTimeSeriesValue[],
    required: true
  },
  clicksSeries: {
    type: Array as () => IntTimeSeriesValue[],
    required: true
  }
},
  setup(__props) {



const chartSeries = computed(() => [
  {
    name: 'Scans',
    data: __props.scansSeries.map((item: any) => item.value)
  },
  {
    name: 'Clicks',
    data: __props.clicksSeries.map((item: any) => item.value)
  }
])

const chartOptions = computed(() => {
  return {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false
      }
    },
    colors: ['#7A3DFF', '#FF7809'],
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: false,
      fontSize: 12,
      // color: var(--Nearly-Black, #212529);

      fontFamily: 'Inter',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      clusterGroupedSeries: true,
      clusterGroupedSeriesOrientation: 'vertical',
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      markers: {
        size: 7,
        style: 'line',
        offsetY: 1.5,
        offsetX: -5
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0
      },
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      }
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      type: 'datetime',
      categories: __props.scansSeries.map((item: any) => item.timestamp)
    },
    yaxis: {
      show: false
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createBlock(AnalyticsCard, { heading: "Scans" }, {
    rightComponent: _withCtx(() => [
      _createVNode(KPIComponent, {
        value: __props.value,
        change: __props.percentageChange
      }, null, 8, ["value", "change"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_apexchart, {
          type: "area",
          height: "350",
          options: chartOptions.value,
          series: chartSeries.value
        }, null, 8, ["options", "series"])
      ])
    ]),
    _: 1
  }))
}
}

})