import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-content container-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex flex-row top-bar-scroll"
}
const _hoisted_4 = { style: {"justify-self":"end"} }
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-row"
}
const _hoisted_6 = { style: {"display":"flex","gap":"0.48rem","justify-self":"end"} }
const _hoisted_7 = { class: "analytics-content" }
const _hoisted_8 = { class: "analytics-content-card-row" }

import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import DateRangeInput from '@/components/input/DateRangeInput.vue'
import LocationScansCard from '@/components/analytics/LocationScansCard.vue'
import NavExportCSVButton from '@/components/button/NavExportCSVButton.vue'
import PageClicksCard from '@/components/analytics/PageClicksCard.vue'
import ScansCard from '@/components/analytics/ScansCard.vue'

import { useAnalyticsStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'AnalyticsView',
  setup(__props) {

const router = useRouter()

const analyticsStore = useAnalyticsStore()
const { startDate, endDate, csvData, kpiClicks, kpiScans, locationScans, sectionClicks, scanTimeSeries, accessTimeSeries } = storeToRefs(analyticsStore)
const { selectDateRange, getCSVDownload, fetchKPIClicks, fetchKPIScans, fetchLocationScans, fetchSectionClicks } = analyticsStore

const scrolling = ref(false)

async function onDownloadCSV () {
  const status = await getCSVDownload()
  if (status !== 200) return

  const href = URL.createObjectURL(csvData.value)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', 'keypin-analytics.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

function updateScroll () {
  scrolling.value = window.scrollY > 150
}

onMounted(async () => {
  window.addEventListener('scroll', updateScroll)

  const status = await Promise.all([
    fetchKPIClicks(),
    fetchKPIScans(),
    fetchLocationScans(),
    fetchSectionClicks()
  ])
  if (status.includes('unauthorized')) router.push('/login')
})

onUnmounted(() => window.removeEventListener('scroll', updateScroll))


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (scrolling.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"flex":"1"} }, [
              _createElementVNode("h1", { class: "page-title page-title-fixed" }, "Analytics")
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(NavExportCSVButton, { onClick: onDownloadCSV })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { style: {"flex":"1"} }, [
              _createElementVNode("h1", { class: "page-title page-title-fixed" }, "Analytics")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(DateRangeInput, {
                "model-value": [_unref(startDate), _unref(endDate)],
                startDate: _unref(startDate),
                endDate: _unref(endDate),
                dateFormat: "MMMM D, YYYY",
                maxDate: _unref(startDate),
                minDate: _unref(endDate),
                onDateChange: _unref(selectDateRange),
                onVisibilityChange: () => null
              }, null, 8, ["model-value", "startDate", "endDate", "maxDate", "minDate", "onDateChange"]),
              _createVNode(NavExportCSVButton, { onClick: onDownloadCSV })
            ])
          ])),
      _createElementVNode("div", _hoisted_7, [
        (_unref(kpiScans))
          ? (_openBlock(), _createBlock(ScansCard, {
              key: 0,
              value: _unref(kpiScans).value.toString(),
              "percentage-change": _unref(kpiScans).percentageChange,
              "scans-series": _unref(scanTimeSeries),
              "clicks-series": _unref(accessTimeSeries)
            }, null, 8, ["value", "percentage-change", "scans-series", "clicks-series"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          (_unref(kpiClicks) && _unref(sectionClicks))
            ? (_openBlock(), _createBlock(PageClicksCard, {
                key: 0,
                value: _unref(kpiClicks).value,
                change: _unref(kpiClicks).percentageChange,
                "section-clicks": _unref(sectionClicks)
              }, null, 8, ["value", "change", "section-clicks"]))
            : _createCommentVNode("", true),
          (_unref(locationScans))
            ? (_openBlock(), _createBlock(LocationScansCard, {
                key: 1,
                locationScans: _unref(locationScans)
              }, null, 8, ["locationScans"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})