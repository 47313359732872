<script setup lang="ts">
import { computed } from 'vue'

const { value, change, changeSuffix } = defineProps({
  value: {
    type: String,
    required: true
  },
  change: {
    type: Number,
    required: true
  },
  changeSuffix: {
    type: String,
    default: '%'
  }
})

const imgSrc = computed(() => {
  const icon = change >= 0 ? 'inc-green' : 'dec-red'
  return require(`@/assets/icons/svg/kpi-${icon}.svg`)
})

const cssVarsChangeText = computed(() => {
  return {
    color: change >= 0 ? 'var(--color-kpi-green)' : 'var(--color-red)'
  }
})

</script>

<template>
    <div class="kpi-container">
        <div class="kpi-change-container">
            <img :src="imgSrc" alt="icon" class="kpi-change-image">
            <p :style="cssVarsChangeText" class="kpi-change-text">{{ `${change.toFixed(1)}${changeSuffix}` }}</p>
        </div>
        <p class="kpi-value-text">{{ value }}</p>
    </div>
</template>

<style scoped lang="scss">
    div.kpi-container {
        display: flex;
        gap: 0.32rem;
        align-items: center;
    }
    div.kpi-change-container {
        display: flex;
        gap: 0.192rem;
        justify-content: space-between;
    }
    div.kpi-change-image {
        width: 0.64rem;
        height: 0.64rem;
    }
    p.kpi-change-text {
        font-family: Inter;
        font-size: var(--font-size-14);
        font-weight: 500;
        margin: 0;
    }
    p.kpi-value-text {
        color: var(--color-nearly-black);
        font-family: Inter;
        font-size: var(--font-size-30);
        font-weight: 800;
        margin: 0;
    }
</style>
