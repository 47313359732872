<script setup lang="ts">

import { LabelValue } from '@/api/types'

import AnalyticsCard from '@/components/analytics/AnalyticsCard.vue'
import KPIComponent from './KPIComponent.vue'

const { locationScans } = defineProps({
  locationScans: {
    type: Array as () => LabelValue[],
    required: true
  }
})

const headingText = 'Scans by location'

</script>

<template>
    <AnalyticsCard :heading="headingText">
        <template #rightComponent>
        </template>
        <template #content>
            <div class="location-scans-card-content">
                <div v-for="{label, value} in locationScans" class="location-scans-card-row" :key="label">
                    <p class="location-scans-card-label-text">{{ label }}</p>
                    <p class="location-scans-card-value-text">{{ value }}</p>
                </div>
            </div>
        </template>
    </AnalyticsCard>
</template>

<style scoped lang="scss">
.location-scans-card-content {
    display: flex;
    flex-direction: column;
    gap: 0.48rem;
    padding-top: 0.32rem;
}

.location-scans-card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location-scans-card-label-text {
    margin: 0;
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 400;
    opacity: 0.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.location-scans-card-value-text {
    margin: 0;
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
}

</style>
