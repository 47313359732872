<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import DateRangeInput from '@/components/input/DateRangeInput.vue'
import LocationScansCard from '@/components/analytics/LocationScansCard.vue'
import NavExportCSVButton from '@/components/button/NavExportCSVButton.vue'
import PageClicksCard from '@/components/analytics/PageClicksCard.vue'
import ScansCard from '@/components/analytics/ScansCard.vue'

import { useAnalyticsStore } from '@/store'

const router = useRouter()

const analyticsStore = useAnalyticsStore()
const { startDate, endDate, csvData, kpiClicks, kpiScans, locationScans, sectionClicks, scanTimeSeries, accessTimeSeries } = storeToRefs(analyticsStore)
const { selectDateRange, getCSVDownload, fetchKPIClicks, fetchKPIScans, fetchLocationScans, fetchSectionClicks } = analyticsStore

const scrolling = ref(false)

async function onDownloadCSV () {
  const status = await getCSVDownload()
  if (status !== 200) return

  const href = URL.createObjectURL(csvData.value)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', 'keypin-analytics.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

function updateScroll () {
  scrolling.value = window.scrollY > 150
}

onMounted(async () => {
  window.addEventListener('scroll', updateScroll)

  const status = await Promise.all([
    fetchKPIClicks(),
    fetchKPIScans(),
    fetchLocationScans(),
    fetchSectionClicks()
  ])
  if (status.includes('unauthorized')) router.push('/login')
})

onUnmounted(() => window.removeEventListener('scroll', updateScroll))

</script>

<template>
    <div class="view-container">
      <div class="view-content container-fluid">
        <div v-if="scrolling" class="d-flex flex-row top-bar-scroll">
            <div style="flex: 1">
            <h1 class="page-title page-title-fixed">Analytics</h1>
          </div>
          <div style="justify-self: end;">
            <NavExportCSVButton @click="onDownloadCSV"/>
          </div>
        </div>
        <div v-else class="d-flex flex-row">
          <div style="flex: 1">
            <h1 class="page-title page-title-fixed">Analytics</h1>
          </div>
          <div style="display: flex; gap: 0.48rem; justify-self: end;">
              <DateRangeInput
                :model-value="[startDate, endDate]"
                :startDate="startDate"
                :endDate="endDate"
                dateFormat="MMMM D, YYYY"
                :maxDate="startDate"
                :minDate="endDate"
                @dateChange="selectDateRange"
                @visibilityChange="() => null"
              />
            <NavExportCSVButton @click="onDownloadCSV"/>
          </div>
        </div>
        <div class="analytics-content">
            <ScansCard
              v-if="kpiScans"
              :value="kpiScans.value.toString()"
              :percentage-change="kpiScans.percentageChange"
              :scans-series="scanTimeSeries"
              :clicks-series="accessTimeSeries"
              />
            <div class="analytics-content-card-row">
              <PageClicksCard
                v-if="kpiClicks && sectionClicks"
                :value="kpiClicks.value"
                :change="kpiClicks.percentageChange"
                :section-clicks="sectionClicks"
              />
              <LocationScansCard
                v-if="locationScans"
                :locationScans="locationScans"
              />
            </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">

h1.page-title {
  color: var(--color-nearly-black);
  font-family: Inter;
  font-weight: 800;
  width: 100%;
  text-align: left;
  margin: 0;
}

h1.page-title-fixed{
  font-size: 30px;
  line-height: 38px; /* 126.667% */
}

h1.page-title-scrolling{
  font-size: 20px;
  line-height: 10px; /* 100% */
  margin: 0;
}

div.top-bar-scroll {
  position: sticky;
  top: 0;
  z-index: 1;
  width: calc(100% + 2 * var(--main-padding));
  margin: 0 0 0 calc(-1 * var(--main-padding));
  padding: 10px var(--main-padding) 10px var(--main-padding);
  background-color: #FFF;
  box-shadow: 0px 10px 10px -15px #111;
}

div.analytics-content {
  display: flex;
  flex-direction: column;
  gap: 0.96rem;
  padding-top: 0.96rem;
}

div.analytics-content-card-row {
  display: flex;
  gap: 0.96rem;
}
</style>
