import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-clicks-card-content" }
const _hoisted_2 = { class: "page-clicks-card-label-text" }
const _hoisted_3 = { class: "page-clicks-card-value-text" }

import { LabelValue } from '@/api/types'

import AnalyticsCard from '@/components/analytics/AnalyticsCard.vue'
import KPIComponent from './KPIComponent.vue'

const headingText = 'Page clicks'


export default /*@__PURE__*/_defineComponent({
  __name: 'PageClicksCard',
  props: {
  value: {
    type: Number,
    required: true
  },
  change: {
    type: Number,
    required: true
  },
  sectionClicks: {
    type: Array as () => LabelValue[],
    required: true
  }
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AnalyticsCard, { heading: headingText }, {
    rightComponent: _withCtx(() => [
      _createVNode(KPIComponent, {
        value: __props.value.toString(),
        change: __props.change
      }, null, 8, ["value", "change"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.sectionClicks, ({label, value}) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "page-clicks-card-row",
            key: label
          }, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(label), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(value), 1)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})