import { ref } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/api'
import { IntTimeSeriesValue, KPI, LabelValue, ListSubProfile } from '@/api/types'
import { useEditorStore } from './editorStore'

export const useAnalyticsStore = defineStore('analytics', () => {
  const startDate = ref<Date>(new Date(Date.now() - 180 * 24 * 60 * 60 * 1000))
  const endDate = ref<Date>(new Date())

  const csvData = ref<any>(null)

  const kpiScans = ref<KPI | null>(null)
  const kpiClicks = ref<KPI | null>(null)

  const scanTimeSeries = ref<IntTimeSeriesValue[]>([])
  const accessTimeSeries = ref<IntTimeSeriesValue[]>([])

  const locationScans = ref<LabelValue[]>([])
  const sectionClicks = ref<LabelValue[]>([])

  async function selectDateRange (range: { startDate: Date, endDate: Date }) {
    startDate.value = range.startDate
    endDate.value = range.endDate

    const status = await Promise.all([
      fetchKPIClicks(),
      fetchKPIScans(),
      fetchLocationScans(),
      fetchSectionClicks()
    ])
  }

  async function fetchKPIScans (): Promise<string> {
    const responses = await Promise.all([
      api.fetchKPIScans(startDate.value, endDate.value),
      api.fetchScanTimeSeries(startDate.value, endDate.value),
      api.fetchAccessTimeSeries(startDate.value, endDate.value)
    ])

    const [kpiResponse, scanTimeSeriesResponse, accessTimeSeriesResponse] = responses
    if (kpiResponse.kind !== 'ok' || scanTimeSeriesResponse.kind !== 'ok' || accessTimeSeriesResponse.kind !== 'ok') {
      console.log(`Error fetching scans KPI: ${JSON.stringify(responses)}`, [])
      return 'error'
    }

    kpiScans.value = kpiResponse.kpi
    scanTimeSeries.value = scanTimeSeriesResponse.scanTimeSeries
    accessTimeSeries.value = accessTimeSeriesResponse.accessTimeSeries
    return 'ok'
  }

  async function fetchKPIClicks (): Promise<string> {
    const response = await api.fetchKPIClicks(startDate.value, endDate.value)
    if (response.kind !== 'ok') {
      console.log(`Error fetching subprofile ids: ${JSON.stringify(response)}`, [])
      return response.kind
    }
    kpiClicks.value = response.kpi
    return response.kind
  }

  async function fetchLocationScans (): Promise<string> {
    const response = await api.fetchLocationScans(startDate.value, endDate.value)
    if (response.kind !== 'ok') {
      console.log(`Error fetching subprofile ids: ${JSON.stringify(response)}`, [])
      return response.kind
    }
    locationScans.value = response.locationScans
    return response.kind
  }

  async function fetchSectionClicks (): Promise<string> {
    const response = await api.fetchSectionClicks(startDate.value, endDate.value)
    if (response.kind !== 'ok') {
      console.log(`Error fetching subprofile ids: ${JSON.stringify(response)}`, [])
      return response.kind
    }
    sectionClicks.value = response.sectionClicks
    return response.kind
  }

  async function getCSVDownload () {
    const response = await api.getAnalCSVDownload()
    if (response.status !== 200) {
      console.log(`Error fetching analytics csv: ${JSON.stringify(response)}`, [])
      return response.status
    }
    csvData.value = response.data
    return response.status
  }

  return {
    startDate,
    endDate,
    csvData,
    kpiScans,
    kpiClicks,
    locationScans,
    sectionClicks,
    scanTimeSeries,
    accessTimeSeries,
    selectDateRange,
    fetchKPIScans,
    fetchKPIClicks,
    fetchLocationScans,
    fetchSectionClicks,
    getCSVDownload
  }
})
