import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/logo-grad.png'
import _imports_1 from '@/assets/logo-text.png'
import _imports_2 from '@/assets/images/figma-qr-codes-scrolling.png'


const _hoisted_1 = { class: "landing-container container-fluid" }
const _hoisted_2 = { class: "top-bar" }
const _hoisted_3 = {
  key: 0,
  class: "top-bar-dash"
}
const _hoisted_4 = {
  key: 1,
  class: "top-bar-dash"
}

import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore, useOnboardingStore } from '@/store'

const headerText = 'Complete QR code platform'
const headerStartText = 'Generate, manage, and track QR codes with custom landing pages.'
const headerButtonText = 'Get Started'


export default /*@__PURE__*/_defineComponent({
  __name: 'LandingView',
  setup(__props) {

const { isAuthenticated } = useAuthStore()
const onboardingStore = useOnboardingStore()
const { setMode } = onboardingStore

const router = useRouter()

function onNavigateLogin () {
  setMode('login')
  router.push('/login')
}

function onNavigateRegister () {
  setMode('register')
  router.push('/login')
}

function onNavigateDashboard () {
  setMode('login') // Necessary?
  router.push('/dashboard')
}

function windowScroll () {
  const navbar = document.getElementById('sticky')
  if (navbar) {
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      navbar.classList.add('nav-sticky')
    } else {
      navbar.classList.remove('nav-sticky')
    }
  }
}
onMounted(async () => {
  window.addEventListener('scroll', (ev) => {
    ev.preventDefault()
    windowScroll()
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        class: "top-bar-logo",
        src: _imports_0
      }, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        class: "top-bar-logo-text",
        src: _imports_1
      }, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "top-bar-links" }, null, -1)),
      (_unref(isAuthenticated))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "top-bar-dash-button top-bar-dash-button-start top-bar-dash-button-start-scroll",
              onClick: onNavigateDashboard
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("p", { class: "top-bar-dash-button-text" }, "Dashboard", -1)
            ]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "top-bar-dash-button top-bar-dash-button-login",
              onClick: onNavigateLogin
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("p", { class: "top-bar-dash-button-text" }, "Login", -1)
            ])),
            _createElementVNode("div", {
              class: "top-bar-dash-button top-bar-dash-button-start",
              onClick: onNavigateRegister
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("p", { class: "top-bar-dash-button-text" }, "Get Started", -1)
            ]))
          ]))
    ]),
    _createElementVNode("div", { class: "header-banner-container" }, [
      _createElementVNode("div", { class: "header-text-container" }, [
        _createElementVNode("p", { class: "header-text" }, _toDisplayString(headerText)),
        _createElementVNode("div", { class: "header-start-container" }, [
          _createElementVNode("p", { class: "header-start-text" }, _toDisplayString(headerStartText)),
          _createElementVNode("div", {
            class: "header-start-button",
            onClick: onNavigateRegister
          }, [
            _createElementVNode("p", { class: "header-start-button-text" }, _toDisplayString(headerButtonText))
          ])
        ])
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "header-carousel-container" }, [
        _createElementVNode("img", {
          class: "header-carousel-image",
          src: _imports_2
        })
      ], -1))
    ])
  ]))
}
}

})